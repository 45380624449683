<template>
  <div>
    <div class="titles">
      <p> Hello! 👋🏾 欢迎来到我的照片墙</p>
    </div>
    <div class="banner">
      <div class="img-list img-wrapper">
        <div class="img-box" v-for="(imgBox, index) in imgBoxList" :key="index">
          <img :src="imgBox.url" :alt="`Image ${index}`">
        </div>
      </div>
    </div>
    <div class="btn-group">
      <button class="last btn" @click="prev">
        <svg t="1686471404424" class="icon left" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg" p-id="2373" width="128" height="128">
          <path
            d="M862.485 481.154H234.126l203.3-203.3c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0L135.397 489.373c-12.497 12.497-12.497 32.758 0 45.254l256.774 256.775c6.249 6.248 14.438 9.372 22.627 9.372s16.379-3.124 22.627-9.372c12.497-12.497 12.497-32.759 0-45.255l-203.3-203.301h628.36c17.036 0 30.846-13.81 30.846-30.846s-13.81-30.846-30.846-30.846z"
            fill="" p-id="2374"></path>
        </svg>
      </button>
      <button class="next btn"  @click="next">
        <svg t="1686471404424" class="icon right" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg" p-id="2373" width="128" height="128">
          <path
            d="M862.485 481.154H234.126l203.3-203.3c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0L135.397 489.373c-12.497 12.497-12.497 32.758 0 45.254l256.774 256.775c6.249 6.248 14.438 9.372 22.627 9.372s16.379-3.124 22.627-9.372c12.497-12.497 12.497-32.759 0-45.255l-203.3-203.301h628.36c17.036 0 30.846-13.81 30.846-30.846s-13.81-30.846-30.846-30.846z"
            fill="" p-id="2374"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      imgBoxList: [],
      index: 0,
      animationTime: 0.5,
      imgBoxCount: 0,
      postSpacing: 0,
      postSize: 0,
      lastImgBox: null,
    };
  },
  mounted() {
    this.init();

  },
  created() {
    this.getImgList()

  },
  methods: {
    init() {
      // 初始化 imgBoxList 和其他数据
      this.imgBoxList = this.dataList.slice(0, 5).map((item, index) => ({
        url: item.url,
        id: index === 5 ? "last-img-box" : "",
      }));
      this.imgBoxCount = this.imgBoxList.length;
      this.postSpacing = Number(getComputedStyle(this.$el).getPropertyValue('--post-spacing').replace("vw", ""));
      this.postSize = Number(getComputedStyle(this.$el).getPropertyValue('--post-size').replace("vw", ""));
      this.lastImgBox = this.imgBoxList.find(imgBox => imgBox.id === "last-img-box");

      // 设置 imgListOne 动画时间
      const imgListOne = this.$el.querySelector('.img-list');
      imgListOne.style.transition = `${this.animationTime}s ease`;

      // 设置按钮出现时间
      setTimeout(() => {
        const btnGroup = this.$el.querySelector('.btn-group');
        btnGroup.style.opacity = '1';
        btnGroup.style.bottom = '5%';
      }, this.animationTime * 1000);
    },
    getImgList() {
      let url = "/photoWall/list";
      this.$http.get(this.$constant.baseURL + url).then((res) => {
        let tempDataList = [];
        // 存储生成的所有图片URL
        let allImgUrls = [];

        // 确保res.data是一个数组
        if (Array.isArray(res.data)) {

          const randomIndex = Math.floor(Math.random() * res.data.length);
          const randomImgObj = res.data[randomIndex];
          for (let i = randomImgObj.min; i <= randomImgObj.max; i++) {
            const imgUrl = randomImgObj.url + i + randomImgObj.type;
            let img = {
              id: i,
              url: imgUrl,
            };
            allImgUrls.push(img);
          }
          this.dataList = allImgUrls;
          console.log(this.dataList.length)
          // let shuffledNumList = this.shuffle(numList.slice()); // 洗牌
          // for (let i = 0; i < shuffledNumList.length; i++) {
          //   let img = {
          //     id: i,
          //     url: item.url + shuffledNumList[i] + item.type,
          //   };
          //   tempDataList.push(img);
          // }
        } else {
          console.error('返回的数据不是预期的数组格式');
        }

        this.dataList = allImgUrls;
        this.updateImgBoxList();

      });
    },
    updateImgBoxList() {
      let dataList = this.dataList.slice(0, this.dataList.length); // 获取前6个元素
      this.dataList = this.dataList.slice(this.dataList.length); // 剔除前6个元素

      this.imgBoxList = dataList.map((item, index) => {
        return {
          style: {},
          url: item.url,
          id: index === 5 ? "last-img-box" : "",
        };
      });
    },
    shuffle(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;

      // 当还剩有元素未洗牌时
      while (0 !== currentIndex) {

        // 选取一个剩余元素…
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // 并与当前元素交换。
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    initImgBoxList() {
      let dataList = this.dataList;
      console.log(dataList)
      const imgBoxCount = 6; // 根据实际图片数量调整
      for (let i = 0; i < imgBoxCount; i++) {
        this.imgBoxList.push({
          style: {}, // 添加style属性
          // url: dataList[i].url,
        });
      }
      this.imgBoxList.forEach((item, index) => {
        item.id = index === imgBoxCount - 1 ? 'last-img-box' : '';
      });
      this.imgBoxList.unshift(this.imgBoxList.pop());
    },
    clickFun(flag) {
      // 下一张 next
      if (flag === 'next') {
        console.log(this.index)
        this.index--;
        if (Math.abs(this.index) === this.imgBoxList.length) {
          this.index = 0;
          this.updateImgBoxList();
          this.resetImgBoxList();
        } else {
          if (this.imgBoxList[0].id === 'last-img-box') {
            this.imgBoxList[0].style.transform = `translateX(-160.68vw)`;
          } else if (this.index >= 0) {
            this.imgBoxList[0].style.transform = 'none';
          } else {
            this.imgBoxList[0].style.transform = 'translateX(160.68vw)';
          }
        }
        this.imgBoxList.push(this.imgBoxList.shift());
      } else {
        // 上一张 last
        this.index++;
        this.imgBoxList.unshift(this.imgBoxList.pop());
        if (this.imgBoxList[0].id === 'last-img-box' && this.index !== 0) {
          this.imgBoxList[0].style.transform = 'translateX(-321.36vw)';
        } else if (this.index < 0) {
          this.imgBoxList[0].style.transform = 'none';
        } else {
          this.imgBoxList[0].style.transform = 'translateX(-160.68vw)';
        }
        if (Math.abs(this.index) === this.imgBoxList.length) {
          this.index = 0;
          this.resetImgBoxList();
        }
      }
    },
    prev() {
      console.log("index",this.index)
      // 上一张图片的逻辑
      if (this.index > 0) {
        this.index--; // 更新索引
        this.moveList(this.index);
      } else if (this.index === 0) {
        // 如果当前是第一张图片，且有最后一张图片，需要循环到最后一张图片
        this.index = this.imgBoxList.length - 1;
        this.moveList(this.index);
      }
    },
    next() {
      console.log("index",this.index)
      // 下一张图片的逻辑
      if (this.index < this.imgBoxList.length - 1) {
        this.index++; // 更新索引
        this.moveList(this.index);
      } else if (this.index === this.imgBoxList.length - 1) {
        // 如果当前是最后一张图片，且有第一张图片，需要循环到第一张图片
        this.index = 0;
        this.moveList(this.index);
      }
    },
    moveList(index) {
      // 移动图片列表的逻辑
      const imgListOne = this.$el.querySelector('.img-list');
      const imgBoxLength = this.postSize + this.postSpacing;
      const totalWidth = imgBoxLength * this.imgBoxList.length;

      // 计算移动的距离
      const moveDistance = -index * imgBoxLength;

      // 更新imgListOne的位置
      imgListOne.style.left = `${moveDistance}vw`;

      // 重置所有图片盒子的变换
      Array.from(this.$el.querySelectorAll('.img-box')).forEach(box => {
        box.style.transform = 'none';
      });

      // 根据index调整特定图片的变换
      const activeBox = this.$el.querySelectorAll('.img-box')[index];
      if (activeBox) {
        // 将当前激活的图片盒子置于正确位置
        // activeBox.style.transform = `translateX(${moveDistance}px)`;
      }

      // 如果需要循环，可以在这里添加逻辑
      if (index === this.imgBoxList.length - 1) {
        // 将第一张图片移动到最后
        const firstBox = this.$el.querySelectorAll('.img-box')[0];
        // firstBox.style.transform = `translateX(${totalWidth - imgBoxLength}px)`;
      }
    },

    resetImgBoxList() {
      setTimeout(() => {
        this.imgBoxList.forEach((item, index) => {
          if (item.id === 'last-img-box') {
            item.style.transform = `translateX(-160.68vw)`;
          } else {
            item.style.transform = 'none';
          }
        });
      }, this.animationTime * 1000);
    },
  },
};
</script>
<style scoped>
@import '../../assets/css/style.css';
/*@font-face {*/
/*  font-family: Millik;*/
/*  font-weight: 700;*/
/*  !*src: url(./asset/font/Millik.c3f91cb.ttf) format("truetype");*!*/
/*  text-rendering: optimizeLegibility;*/
/*}*/

/*:root {*/
/*  --post-spacing: 1.78vw;*/
/*  --post-size: 25vw;*/
/*  --mask-size: 100vw;*/
/*}*/

/** {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  !*font-family: Millik, Arial, sans-serif;*!*/
/*  font-size: 62.5%;*/
/*  -ms-text-size-adjust: 100%;*/
/*  -webkit-text-size-adjust: 100%;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -webkit-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*}*/

/*body {*/
/*  background: #fff;*/
/*  background-image: url(../../assets/file/grid.svg);*/
/*  background-repeat: repeat;*/
/*  background-size: 300px 300px;*/
/*}*/

/*.titles {*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  top: 5%;*/
/*  -webkit-transform: translate(-50%, -5%);*/
/*  transform: translate(-50%, -5%);*/
/*  margin-top: 50px;*/

/*}*/

/*.titles p {*/
/*  font-size: 4rem;*/
/*  font-weight: 800;*/
/*  white-space: nowrap;*/
/*}*/

/*.banner {*/
/*  margin-top: 50px;*/
/*  overflow: hidden;*/
/*  position: relative;*/
/*  width: 100vw;*/
/*  height: calc(var(--post-size) / 0.72);*/
/*  -webkit-mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNDQwIiBoZWlnaHQ9IjUwMCIgdmlld0JveD0iMCAwIDE0NDAgNTAwIiBpZD0iaiI+CiAgPHBhdGggZmlsbD0icmdiKDIwMCwyMDAsMjAwKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwczI3NS4wNCAxMDAgNzIwIDEwMFMxNDQwIDAgMTQ0MCAwdjUwMHMtMjc1LjA0LTEwMC03MjAtMTAwUzAgNTAwIDAgNTAwVjB6Ii8+Cjwvc3ZnPgo=);*/
/*  mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNDQwIiBoZWlnaHQ9IjUwMCIgdmlld0JveD0iMCAwIDE0NDAgNTAwIiBpZD0iaiI+CiAgPHBhdGggZmlsbD0icmdiKDIwMCwyMDAsMjAwKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwczI3NS4wNCAxMDAgNzIwIDEwMFMxNDQwIDAgMTQ0MCAwdjUwMHMtMjc1LjA0LTEwMC03MjAtMTAwUzAgNTAwIDAgNTAwVjB6Ii8+Cjwvc3ZnPgo=);*/
/*  -webkit-mask-repeat: no-repeat;*/
/*  mask-repeat: no-repeat;*/
/*  -webkit-mask-position: center;*/
/*  mask-position: center;*/
/*  -webkit-mask-size: var(--mask-size);*/
/*  mask-size: var(--mask-size);*/
/*  position: absolute;*/
/*  top: 10%;*/
/*}*/

/*.banner .img-wrapper {*/
/*  display: -webkit-box;*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  float: left;*/
/*  height: calc(var(--post-size) / 0.72);*/
/*  -webkit-transform: translate(13.39vw, 0);*/
/*  transform: translate(13.39vw, 0);*/
/*  -webkit-animation: admission 1.5s;*/
/*  animation: admission 1.5s;*/
/*}*/

/*.banner .img-wrapper .img-box {*/
/*  height: 100%;*/
/*  display: inline-block;*/
/*  margin-right: var(--post-spacing);*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*}*/

/*.banner .img-wrapper .img-box .info {*/
/*  position: absolute;*/
/*  display: -webkit-box;*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  -webkit-box-orient: vertical;*/
/*  -webkit-box-direction: normal;*/
/*  -ms-flex-direction: column;*/
/*  flex-direction: column;*/
/*  -webkit-box-pack: center;*/
/*  -ms-flex-pack: center;*/
/*  justify-content: center;*/
/*  left: 0;*/
/*  top: 0;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  background: rgba(23, 23, 23, 0.5);*/
/*  !*text-align: center;*!*/
/*  color: #fff9f1;*/
/*  font-size: 4rem;*/
/*}*/

/*.banner .img-wrapper .img-box img {*/
/*  width: var(--post-size);*/
/*  height: 100%;*/
/*  -o-object-position: center;*/
/*  object-position: center;*/
/*  -o-object-fit: cover;*/
/*  object-fit: cover;*/
/*}*/

/*.banner .img-wrapper .img-box:last-child {*/
/*  -webkit-transform: translate(-160.68vw, 0);*/
/*  transform: translate(-160.68vw, 0);*/
/*}*/

/*.btn-group {*/
/*  height: 15vh;*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  bottom: 0%;*/
/*  -webkit-transform: translate(-50%, -50%);*/
/*  transform: translate(-50%, -50%);*/
/*  -webkit-transition: 1s;*/
/*  transition: 1s;*/
/*  !*opacity: 0;*!*/
/*}*/

/*.btn-group .btn {*/
/*  width: 60px;*/
/*  height: 60px;*/
/*  border-radius: 50%;*/
/*  border: 1px solid #171717;*/
/*  background-color: #fff;*/
/*  margin: 10px;*/
/*  cursor: pointer;*/
/*  -webkit-transition: 0.4s;*/
/*  transition: 0.4s;*/
/*  -webkit-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*}*/

/*.btn-group .btn:hover {*/
/*  -webkit-transform: scale(1.2);*/
/*  transform: scale(1.2);*/
/*  background-color: #000;*/
/*}*/

/*.btn-group .btn:hover .icon {*/
/*  fill: #fff;*/
/*}*/

/*.btn-group .btn .icon {*/
/*  width: 30px;*/
/*  height: 30px;*/
/*}*/

/*.btn-group .btn .right {*/
/*  -webkit-transform: rotate(180deg);*/
/*  transform: rotate(180deg);*/
/*}*/

/*.img-list {*/
/*  left: 0;*/
/*}*/

/*@-webkit-keyframes admission {*/
/*  0% {*/
/*    -webkit-transform: translate(140vw, 0);*/
/*    transform: translate(140vw, 0);*/
/*  }*/
/*  100% {*/
/*    -webkit-transform: translate(13.39vw, 0);*/
/*    transform: translate(13.39vw, 0);*/
/*  }*/
/*}*/

/*@keyframes admission {*/
/*  0% {*/
/*    -webkit-transform: translate(140vw, 0);*/
/*    transform: translate(140vw, 0);*/
/*  }*/
/*  100% {*/
/*    -webkit-transform: translate(13.39vw, 0);*/
/*    transform: translate(13.39vw, 0);*/
/*  }*/
/*}*/
/*!*# sourceMappingURL=style.css.map *!*/

</style>
