<template>
  <div>
    <div v-if="!isPc">
      <div class="friend-head myCenter">
        <h1 style="z-index: 10;font-weight: 700;font-size: 45px">照片墙</h1>
      </div>
      <div class="shell" style="animation: hideToShow 2s">
        <div class="image" v-for="item in dataList" :key="item.index">
          <img :src="item.url">
        </div>
      </div>
      <my-footer></my-footer>
    </div>
    <photo-slide-show v-else>
    </photo-slide-show>
  </div>


</template>

<script>
const myFooter = () => import( "../common/myFooter");
import photoSlideShow from "@/components/common/photoSlideShow";
export default {
  name: "photoWall",
    components: {
      myFooter,
      photoSlideShow
    },

    data() {
      return {
        allDataList: [],
        dataList:[],
        isPc: false,
        loadedImages: 0, // 已加载的图片数量
        totalImages: 20, // 需要加载的总图片数量
      }
  },
  methods:{
    checkIsPc() {
      //判断是否为PC端
      let userAgentInfo = navigator.userAgent;
      let Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
      this.isPc = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          this.isPc = false;
          break;
        }
      }
    },
    getImgList() {
      let url = "/photoWall/list";
      this.$http.get(this.$constant.baseURL + url).then((res) => {
        let tempDataList = [];
        // 存储生成的所有图片URL
        let allImgUrls = [];

        // 确保res.data是一个数组
        if (Array.isArray(res.data)) {

          const randomIndex = Math.floor(Math.random() * res.data.length);
          const randomImgObj = res.data[randomIndex];
          for (let i = randomImgObj.min; i <= randomImgObj.max; i++) {
            const imgUrl = randomImgObj.url + i + randomImgObj.type;
            let img = {
              id: i,
              url: imgUrl,
            };
            allImgUrls.push(img);
          }
          this.dataList = allImgUrls;
          console.log(this.dataList.length)
          // let shuffledNumList = this.shuffle(numList.slice()); // 洗牌
          // for (let i = 0; i < shuffledNumList.length; i++) {
          //   let img = {
          //     id: i,
          //     url: item.url + shuffledNumList[i] + item.type,
          //   };
          //   tempDataList.push(img);
          // }
        } else {
          console.error('返回的数据不是预期的数组格式');
        }

        this.dataList = allImgUrls;
        // this.updateImgBoxList();

      });
    },
    shuffle(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;

      // 当还剩有元素未洗牌时
      while (0 !== currentIndex) {

        // 选取一个剩余元素…
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // 并与当前元素交换。
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    handleScroll() {
      // 页面滚动到底部时触发加载更多图片的逻辑
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.loadMoreImages();
      }
    },
    loadMoreImages() {
      // // 加载更多图片
      // console.log('加载更多图片')
      // console.log("加载的图片数量",this.loadedImages)
      // console.log("总共的图片数量",this.totalImages)
      // for (let i = this.loadedImages; i < this.loadedImages + this.totalImages && i < this.allDataList.length; i++) {
      //   // console.log("加载的图片数量",this.loadedImages)
      //   // console.log("总共的图片数量",this.totalImages)
      //   this.dataList.push(this.allDataList[i]);
      //
      //   this.loadedImages++;

      // }
    }
  },
  created() {
    this.getImgList()
    this.checkIsPc()
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

}
</script>

<style scoped>
.friend-head {
  height: 200px;
  position: relative;
}
.friend-head::before {
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-image: linear-gradient(to right, transparent, #0095ff);*/
  /*background-image: linear-gradient(to right, #ff5e62, #fbc2eb, #a6cbf9, #6a89cc, #40e0d0);*/
  background-image: linear-gradient(to right, transparent, #fbc2eb, #a6cbf9, #6a89cc, transparent);
  /*background-color: rgba(0, 0, 0, .3);*/
  content: '';
}
body{
  /*margin-top: 200px;*/
  /*margin-right: 200px;*/
  background-color: rgba(130, 140, 250, 0.2);
  display: flex;
  justify-content: center;
}
.shell{
  /*margin-top: 50px;*/
  /*margin-left: auto;*/
  /*max-width: 1300px;*/
  column-count: 5;
  column-gap: 15px;
}
.image{
  /*margin: 800px auto 0px 50px;*/
  margin-bottom: 15px;
}
.image img{
  width: 100%;
}
@media (max-width:1200px){
  .shell{
    column-count: 4;
  }
}
@media (max-width:850px){
  .shell{
    column-count: 3;
  }
}
@media (max-width:600px){
  .shell{
    column-count: 2;
  }
}
</style>
